.attachment-upload-container {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    font-size: 15px;
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    background-color: rgb(44, 39, 39);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    height: 200px;
}

.attachment-upload-content {
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.attachment-upload-actions-delete {
    height: 100%;
    width: 30px;
    color: #f54242;
    display: flex;
    border-top-right-radius: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.attachment-upload-actions-delete:hover {
    animation: color 0.5s ease;
    animation-fill-mode: forwards;
}

@keyframes color {
    from {background-color: transparent; color: #f54242;}
    to {background-color: #f54242; color: #fff;}
}

.attachment-upload-actions {
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 200px;
    position: absolute;
    margin-bottom: 170px;
    height: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: rgb(26, 23, 23);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
}

.attachment-container {
    max-width: 200px;
    max-height: 200px;
}

.attachment-container img {
    border-radius: 5px;
    cursor: pointer;
    max-width: 200px;
    max-height: 200px;
}

.attachment-upload-container img {
    margin-bottom: auto;
    border-radius: 5px;
    max-width: 100%;
    max-height: 140px;
}

.attachment-upload-container span {
    margin-top: 10px;
}