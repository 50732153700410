* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

:root {
  color-scheme: dark;
}

::-webkit-scrollbar-track-piece {
  background-color: #fff;
}

a {
  color: #256894;
}

body {
  background-color: rgb(26, 23, 23);
}

.msg-container * {
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}

.msg-container h3 {
  font-size: 32px;
  text-align: center;
  margin-top: 10px;
}

.msg-container p {
  text-align: center;
  margin-top: 15px;
  font-size: 16px;
}

.msg-btn-container { 
  display: flex;
  justify-content: center;
  align-items: center;
}

.msg-btn-container button {
  margin-right: 10px;
  margin-top: 25px;
  border: 1px solid rgb(26, 23, 23);
}

.msg-container {
  color: #fff;
  width: 450px;
  padding-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
  display: inline;
  background-color: rgb(26, 23, 23);
  border-radius: 5px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%)
}

.loading-container {
  height: 300px;
  width: 400px;
  background-color: rgb(26, 23, 23);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 5px;
  border: 2px solid rgb(26, 23, 23);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 50px 35px;
}

.loading-container h3 {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  margin-top: -30px;
  font-size: 32px;
  text-align: center;
}

.load-icon {
  border: 16px solid #fff;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  margin: auto;
  margin-top: 35px;
  width: 150px;
  height: 150px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.home-img {
  background: url('../public/images/img-home4.jpg') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}