.login-form {    
    height: 490px;
    width: 400px;
    background-color: rgb(26, 23, 23);
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    border-radius: 5px;
    border: 2px solid rgb(26, 23, 23);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    padding: 50px 35px;
}

.login-form-submit {
    width: 75%;
    position: fixed;
    text-align: center;
    top: 76%;
    left: 50%;
    transform: translate(-50%, 0)
}

.signup-hint {
    position: fixed;
    text-align: center;
    top: 88.5%;
    width: 90%;
    font-size: small;
    color: #fff;
    left: 50%;
    transform: translate(-50%, 0)
} .reset-hint {
    position: fixed;
    text-align: center;
    top: 93.5%;
    width: 90%;
    font-size: small;
    color: #fff;
    left: 50%;
    transform: translate(-50%, 0)
}

.login-form *{
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}

.login-form h3{
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    font-size: 32px;
    text-align: center;
}

.login-form label{
    display: block;
    color: #fff;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
}

.login-form input{
    display: block;
    height: 50px;
    width: 100%;
    color: #fff;
    background-color: rgba(79,79,79,0.05);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
}

.login-form::placeholder{
    color: #e5e5e5;
}