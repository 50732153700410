.dropdown-container {
    color: #000;
    width: 320px;
    height: 30px;
    font-size: 15px;
    display: flex;
    margin-top: 10px;
    align-items: left;
    text-align: left;
    border: 1px solid #ccc;
    position: relative;
    border-radius: 5px;
}

.dropdown-logo {
    background: var(--image) no-repeat top left;
    width: 32px;
    height: 32px;
    display: inline-block;
}