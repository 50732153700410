* {
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}

.panel__item {
    display: inline-block;
    text-align: center;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.panel__header {
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: top;
    flex-direction: column;
    padding-top: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--color);
    height: 150px;
}

.panel__container p {
    width: 320px;
    overflow-wrap: break-word;
    margin-top: 10px;
}

.panel__footer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: start;
    margin-top: 20px;
    gap: 5px;
    text-align: left;
}

.panel__container h3:first-of-type {
    margin-top: 8px;
}

.panel__container h3 {
    width: 320px;
    margin-top: 15px;
    font-size: 20px;
    color: var(--color);
    text-align: left;
}

.panel__container {
    display: inline-block; 
    vertical-align: top;
    color: #fff;
    max-width: 350px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding-left: 15px;
    padding-bottom: 25px;
    padding-right: 15px;
    background-color: rgb(26, 23, 23);
}

.panel__btn {
    margin-top: 25px;
}

.panel__header h3 {
    color: var(--color);
    font-size: 30px;
    text-align: center;
}

.panel__header i::before {
    color:  var(--color);
    transform: scale(2.5);
    display: block;
    margin-top: 40px;
    position: inherit;
}

.panels__items {
    list-style: none;
    height: 100%;
}