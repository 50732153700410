.ticket-not-found * {
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}

.chat-outer-container {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    height: 90vh;
}

.ticket-not-found-btns-container {
    display: flex;
    text-align: center;
    width: 100%;
    margin-top: -10px;
    height: 70%;
    padding: 12px 2px;
    justify-content: center;
}

.ticket-not-found-submit {
    width: 75%;
    position: fixed;
    text-align: center;
    top: 73%;
    left: 50%;
    transform: translate(-50%, 0)
}

.ticket-not-found-submit-secondary {
    width: 70%;
    text-align: center;
    left: 35%;
}

.ticket-not-found-submit-third {
    width: 40%;
    text-align: center;
    margin-left: 10px;
    left: 78%;
}

.ticket-not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: 400px;
    background-color: rgb(26, 23, 23);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 5px;
    border: 2px solid rgb(26, 23, 23);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 20px 35px;
}

.ticket-not-found h3 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    font-size: 32px;
    text-align: center;
}

.ticket-not-found p {
    display: block;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}