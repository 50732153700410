.ticketlist-container {
    width: 85%;
    display: flex;
    flex-direction: column;
    vertical-align: top;
    margin: auto;
}

.ticketlist-info {
    text-align: center;
    padding-top: 20px;
    display: block;
    color: #fff;
    width: 75%;
    line-height: 8px;
    margin: auto;
}

.ticketlist-info h3 {
    margin-bottom: 15px;
    font-size: 30px;
}

.ticketlist-info p {
    line-height: 20px;
    font-size: 20px;
    margin-top: 2px;
}

.ticketlist-content {
    display: block;
    width: 70%;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 50px;
}

.ticketlist-content-header {
    color: var(--color);
    text-align: left;
    padding-bottom: 15px;
    font-size: 30px;
}

.ticketlist-content-header hr {
    height: 3px;
    background-color: var(--color);
}

.ticketlist-item {
    display: flex;
    justify-content: left;
    align-items: left;
    cursor: pointer;
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 58px;
    background-color: rgb(26, 23, 23);
    border-radius: 5px;
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
}

.ticketlist-item-actions {
    display: flex;
    margin-right: 20px;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.ticketlist-item-actions i {
    margin-left: 10px;
}

.ticketlist-item-dropdown {
    background-color: rgb(26, 23, 23);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    border-radius: 5px;
    padding-bottom: 10px;
    flex-direction: column;
    justify-content: top;
    width: 100%;
    animation: slide 2s linear;
}

.ticketlist-item-dropdown-btns {
    display: flex;
    white-space: nowrap;
    flex-wrap: wrap;
    max-height: 45px;
    width: 50%;
    justify-content: right;
    margin-right: 10px;
    margin-left: auto;
}

.ticketlist-item-dropdown-info {
    margin-left: 10px;
    color: #fff;
    font-size: 20px;
    width: 45%;
}

.ticketlist-item-dropdown-content {
    display: flex;
    flex-direction: row;
}

.ticketlist-item-dropdown-header {
    background-color: var(--color);
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 30px;
}

.ticketlist-item-info {
    margin-left: 8px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    height: 100%;
    color: #fff;
}

.ticketlist-item-img {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color);
    width: 80px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.ticketlist-item-img i::before {
    color: #fff;
}

@media screen and (max-width: 960px) {
    .ticketlist-info h3 {
        font-size: 20px;
    }

    .ticketlist-info p {
        font-size: 15px;
    }

    .ticketlist-item-dropdown-header {
        font-size: 15px;
    }

    .ticketlist-item-dropdown-info {
        width: 100%;
        font-size: 15px;
    }

    .ticketlist-content-header {
        font-size: 15px;
    }

    .ticketlist-item-actions {
        font-size: 15px;
    }

    .ticketlist-item-info {
        font-size: 10px;
    }

    .ticketlist-container {
        width: 100%;
    }

    .ticketlist-item-dropdown-btns {
        width: fit-content;
        height: fit-content;
    }

    .ticketlist-item-dropdown-btns button {
        padding: 0px 10px;
        font-size: 10px;
    }
}