#background-api-unavailable {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 100px;
    height: 100vh;
    background-color: rgb(35, 31, 31);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

#title-api-unavailable {
    margin-bottom: 100px;
    font-size: 40px;
}

#items-api-unavailable {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 60px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

#background-api-unavailable i {
    color: #f5a442;
    font-size: 125px;
    margin-bottom: 100px;
}

#background-api-unavailable a {
    font-size: 18px;
    margin-top: 10px;
}