.popup-flags-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 10px;
}

.popup-flag-content-container {
    display: flex;
    background-color: rgb(35, 31, 31);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    padding: 15px 15px;
}

#popup-change-perm-btn-container {
    gap: 10px;
    justify-content: center;
    align-content: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 0;
}

#popup-change-perm-select {
    margin-top: -15px;
}