.account-viewer-body {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 40px;
    display: flex;
    overflow: auto;
    width: 85%;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px
}

.account-viewer-header {
    cursor: pointer;
    align-items: center;
    flex-direction: row;
    display: flex;
    gap: 10px;
    padding: 5px 15px;
    background-color: rgb(26, 23, 23);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    width: 90%;
    height: 50px;
    border-radius: 5px;
}

.account-viewer-header span {
    font-size: 12px;
    color: #f5a442;
}

.account-viewer-header img {
    border-radius: 500px;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    max-width: 45px;
}

.info-header {
    background-image: var(--image);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(35, 31, 31);
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 10px;
    padding: 10px 10px;
    border-radius: 5px;
    align-items: center;
    justify-content: start;
}

.account-viewer-container {
    width: 90%;
    gap: 10px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.account-viewer-container #info-container {
    width: 500px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 15px;
    background-color: rgb(26, 23, 23);
    border-radius: 5px;
    gap: 10px;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
}

.account-viewer-container #discord-user-container {
    width: 500px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 15px;
    background-color: rgb(26, 23, 23);
    border-radius: 5px;
    gap: 10px;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
}

.account-viewer-container #info-container-elements {
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.account-viewer-container .info-container-element {
    background-color: rgb(35, 31, 31);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 8px;
    border-radius: 5px;
    text-align: center;
}

.account-viewer-container #actions-container {
    width: 400px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 15px;
    background-color: rgb(26, 23, 23);
    border-radius: 5px;
    gap: 10px;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
}

.account-viewer-container #actions-container #actions-container-elements {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}

.discord-background {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    height: 100px;
}

.discord-background-overlay {
    border-radius: 5px;
    width: 80%;
    z-index: 100;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
}

.account-viewer-container #info-container img {
    max-width: 125px;
    border-radius: 500px;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
}

.account-viewer-container #discord-user-container #discord-profile {
    max-width: 125px;
    border-radius: 500px;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
}

.account-viewer-container #discord-info-container-elements {
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.account-viewer-container #discord-info-container-elements {
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}