:root {
    --me: #34a8eb;
    --you: #4f4f4f;
}

.message-container-me {
    --color: var(--me);
    width: 100%;
    margin-top: 5px;
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.message-container-you {
    --color: var(--you);
    width: 100%;
    margin-top: 5px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    position: inherit;
}

.message-body {
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    max-width: var(--width);
    padding-left: 5px;
    padding-right: 5px;
    margin-left: var(--marginLeft);
    margin-right: var(--marginRight);
    word-break: break-all;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: var(--color);
}

.message-body p {
    word-wrap: break-word;
    word-break: keep-all;
    display: inline-block;
    font-size: 15px;
    margin: 5px 5px;
}

.message-action-row {
    display: flex;
    flex-direction: row;
    gap: 5px;
    max-width: 100%;
    height: 15px;
    align-items: center;
    justify-content: var(--justifyContent);
}

.message-action-row .delete-btn {
    cursor: pointer;
    color: #f54242;
}

.message-link {
    word-wrap: break-word;
}

.message-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: var(--justifyContent);
    width: 100%;
    height: 30px;
    flex-direction: row;
}

.message-header img {
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    width: 30px;
    border-radius: 5px;
    background-color: var(--color);
}

.message-header span {
    font-size: 15px;
    margin-right: 6px;
    margin-left: 6px;
}