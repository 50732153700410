.pagenotfound-header {
    color: #fff;
    background-color: rgb(26, 23, 23, 0.6);
    padding-top: 5px;
    width: 100%;
    height: 27%;
    top: 35%;
    font-size: 90px;
    position: fixed;
    margin: auto;
    text-align: center;
}

.pagenotfound-notice {
    color: #fff;
    width: 100%;
    height: 25%;
    top: 48%;
    font-size: 30px;
    position: fixed;
    margin: auto;
    text-align: center;
}

.pagenotfound-btn {
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    align-items: center;
    top: 55%;
    position: fixed;
    margin: auto;
    text-align: center;
}