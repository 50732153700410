.staff-home-body-container {
    flex-wrap: wrap;
    width: 75%;
    display: flex;
    flex-direction: column;
    margin: auto;
    overflow: auto;
}

.staff-home-member-info-container {
    padding-top: 15px;
    width: 100%;
    gap: 10px;
    min-width: 1000px;
    flex-direction: row;
    justify-content: center;
    display: flex;
}

.staff-home-member-info-left-container {
    max-width: 30%;
    width: 30%;
    gap: 10px;
    padding-left: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
}

.staff-home-member-info-right-staff-info-container #policy-grid {
    background-color: rgb(26, 23, 23);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.staff-home-member-info-right-staff-info-container #policy-grid-body {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    width: 99%;
    flex-direction: column;
}

.policy-grid-item #item-blue {
    background-color: rgb(0,91,175);
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
}

.policy-grid-item #item-red {
    background-color: rgb(175, 0, 0);
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
}

.policy-grid-item {
    width: 100%;
    height: 40px;
    color: #000;
    cursor: pointer;
    display: flex;
    gap: 15px;
    border-bottom: solid 1px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.25em;
}

.staff-home-member-info-left-staff-info-details {
    flex-direction: column;
    display: flex;
}

.staff-home-member-info-left-staff-info-details #header {
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding-left: 10px;
    font-size: 0.9rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: rgb(35, 31, 31);
    z-index: 100;
}

.staff-btn {
    white-space: nowrap;
    width: auto;
    min-height: 40px;
    border: 1px solid rgb(26, 23, 23);
}

.staff-home-member-info-left-staff-info-details #body {
    background-color: rgb(26, 23, 23);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: left;
    align-items: start;
    flex-direction: column;
    width: 100%;
}

.staff-home-member-info-right-container {
    width: 55%;
    max-width: 55%;
    flex-direction: column;
    display: flex;
    gap: 10px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
}

.staff-home-member-info-right-staff-info-container #header {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding-left: 10px;
    font-size: 1rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: rgb(35, 31, 31);
    z-index: 100;
}

.staff-home-member-info-right-staff-info-container #body {
    background-color: rgb(26, 23, 23);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 15px;
    padding-top: 15px;
    gap: 10px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
}

.staff-home-member-info-right-staff-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.staff-home-member-info-left-staff-info-detail {
    display: flex;
    width: 100%;
    height: 50px;
    border-bottom: solid 1px rgb(67, 65, 65);
    align-items: center;
    gap: 15px;
    font-size: 1.1rem;
    flex-direction: row;
}

.staff-home-member-info-left-staff-info-detail h3 {
    color: rgb(67, 65, 65);
}

.staff-home-member-info-left-staff-info-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 15px 15px;
    align-items: center;
    background-color: rgb(26, 23, 23);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    border-radius: 5px;
}

.staff-home-member-info-left-staff-info-container img {
    max-width: 100px;
    border-radius: 100px;
    background-color: rgb(35, 31, 31);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
}

.staff-home-member-info-left-staff-info-container h3 {
    font-size: 1.5rem;
}

.staff-home-member-info-left-staff-info-container span {
    font-size: 1.05rem;
}

.staff-home-member-info-left-staff-info-container #staff-rank {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(35, 31, 31);
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    line-height: 20px;
    text-align: center;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
}