.user-view-body {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}

#search-bar-holder {
    padding-left: 10px;
    background-color: rgb(58,58,58);
    width: fit-content;
    display: flex;
    height: fit-content;
    border-radius: 5px;
    flex-direction: row;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.user-view-searchbar {
    width: 480px;
    height: 35px;
    border-radius: 5px;
    text-align: left;
    padding: 0 10px;
}

.user-view-container {
    width: 35vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    background-color: rgb(26, 23, 23);
    justify-content: start;
    padding: 15px 15px;
    align-items: center;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
}

.user-view-container-info-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.admin-btn {
    white-space: nowrap;
    width: auto;
    min-height: 40px;
    font-size: 1.1em;
}

.user-view-container-info {
    background-color: rgb(35, 31, 31);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 8px;
    border-radius: 5px;
    text-align: center;
}

.user-view-container img {
    max-width: 100px;
    border-radius: 500px;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
}