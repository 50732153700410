.switch-panel-container {
    display: flex;
    gap: 15px;
    flex-direction: column;
}

#switch-panel-select {
    margin-top: -15px;
}

#switch-panel-btn-container {
    gap: 10px;
    justify-content: center;
    align-content: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 15px;
    padding-bottom: 10px;
}

#misc-btns {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
}