.reset-form {
    background-color: rgb(26, 23, 23);
    position: absolute;
    transform: translate(-50%,-50%);
    width: 450px;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    border: 2px solid rgb(26, 23, 23);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.reset-form h3{
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    font-size: 32px;
}

.reset-form input {
    width: 100%;
    padding: 15px 10px;

    color: #fff;
    background-color: rgba(79,79,79,0.05);
    border-radius: 3px;
    font-size: 14px;
    margin-top: 3px;
    font-weight: 300;
}

.reset-form-elements {
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.reset-form-submit {
    margin-top: 20px;
    width: 100%;
}