#background {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 100px;
    height: calc(100vh - 80px);
    background-color: rgb(35, 31, 31);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

#title {
    margin-bottom: 100px;
    font-size: 40px;
}

#items {
    margin-bottom: 60px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

#background i {
    color: #c93434;
    font-size: 125px;
    margin-bottom: 100px;
}

#background a {
    font-size: 18px;
    margin-top: 10px;
}