.popup-background {
    position: absolute;
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 997;
    width: 100%;
    height: fit-content;
    min-height: 100vh;
}

.popup-container {
    display: flex;
    flex-direction: column;
    width: 750px;
    background-color: rgb(26, 23, 23);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    border-radius: 5px;
    z-index: 999;
}

.popup-header {
    min-height: 45px;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: rgb(35, 31, 31);
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
    font-size: 1.5em;
}

.popup-body {
    word-wrap: break-word;
    white-space: pre-line;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.popup-content {
    display: flex;
    padding: 15px;
    padding-bottom: 20px;
    flex-direction: column;
    gap: 15px;
}

.popup-field h3 {
    color: rgb(67, 65, 65);
}

.popup-field span {
    word-wrap: break-word;
    white-space: pre-line;
}

.popup-header #red-notice {
    background-color: rgb(175, 0, 0);
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
}

.popup-header #blue-notice {
    background-color: rgb(0,91,175);
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
}

@media screen and (max-width: 960px) {
    .popup-container {
        width: 85%
    }
}