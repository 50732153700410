.verify-form {    
    height: 320px;
    width: 450px;
    background-color: rgb(26, 23, 23);
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    border-radius: 5px;
    border: 2px solid rgb(26, 23, 23);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    padding: 50px 35px;
}

.username-form {    
    height: 290px;
    width: 450px;
    background-color: rgb(26, 23, 23);
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    border-radius: 5px;
    border: 2px solid rgb(26, 23, 23);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    padding: 50px 35px;
}

.verify-form-change {
    width: 60%;
    position: fixed;
    text-align: center;
    top: 77%;
    left: 50%;
    transform: translate(-50%, 0)
}

.verify-form-submit {
    width: 50%;
    position: fixed;
    text-align: center;
    top: 60%;
    left: 50%;
    transform: translate(-50%, 0)
}

.username-form-submit {
    width: 50%;
    position: fixed;
    text-align: center;
    top: 72%;
    left: 50%;
    transform: translate(-50%, 0)
}

.verify-form, .username-form *{
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}

.verify-form h3{
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    margin-top: -25px;
    font-size: 32px;
    text-align: center;
}

.username-form h3{
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    margin-top: -25px;
    font-size: 32px;
    text-align: center;
}

.verify-form label{
    display: block;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}

.username-form label{
    display: block;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}

.verify-form input{
    display: block;
    height: 50px;
    width: 100%;
    text-align: center;
    color: #fff;
    background-color: rgba(79,79,79,0.05);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 300;
}

.username-form input{
    display: block;
    height: 50px;
    width: 100%;
    text-align: center;
    color: #fff;
    background-color: rgba(79,79,79,0.05);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 300;
}

.verify-form::placeholder{
    color: #e5e5e5;
}