.chat-msg-outer-container {
    margin-top: 10px;
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    width: 1000px;
    height: 89.5vh;
    border-radius: 5px;
    display: flex;
    background-color: rgb(26, 23, 23);
    flex-direction: column;
}

.other-chat-options {
    display: flex;
    flex-direction: row;
    background-color: rgb(26, 23, 23);
    gap: 8px;
    padding-top: 5px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-right: 10px;
}

.other-chat-options input {
    width: 18px;
    margin-top: 1px;
    height: 18px;
}

.other-chat-options span {
    color: #f5a442;
}

.chat-notice-outer-container {
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    width: 100%;
    margin-bottom: 5px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    padding: 10px 10px;
    color: #fff;
    background-color: var(--color);
}

.chat-notice-section {
    border-radius: 5px;
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    margin-bottom: auto;
    background-color: rgb(26, 23, 23);
    display: flex;
    max-width: 400px;
    flex-direction: column;
    gap: 10px;
}

.chat-notice-section-header {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    align-items: center;
    background-color: rgb(35, 31, 31);
    height: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.chat-notice-section-content-field-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.chat-notice-section-content-field-header img {
    max-width: 25px;
    margin-right: 5px;
    border-radius: 20px;
}

.chat-notice-section-header h3 {
    margin-left: 15px;
}

.chat-notice-section-content-field {
    word-wrap: break-word;
    white-space: pre-line;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    font-size: 15px;
}

.chat-notice-header {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.chat-notice-content-container {
    gap: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.chat-notice-header img {
    width: 30px;
    border-radius: 5px;
}

.chat-notice-header span {
    font-size: 15px;
    margin-right: 6px;
    margin-left: 6px;
}

.chat-notice-section-content {
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
}

.chat-msg-notice {
    min-height: 35px;
    width: 100%;
    height: 35px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #4a4a4a;
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
}

.chat-msg-attachment-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 10px;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.chat-msg-error {
    min-height: 35px;
    width: 100%;
    height: 35px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #f54242;
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
}

.chat-msg-notice i {
    margin-left: 3px;
    animation: spin 2s linear infinite;
}

.chat-msg-input-outer-container {
    background-color: #525252;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.chat-outer-header {
    cursor: pointer;
    width: 100%;
    min-height: 65px;
    padding-left: 20px;
    padding-right: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    color: #fff;
    align-items: center;
    font-size: 23px;
    flex-direction: row;
    height: 65px;
    background-color: #525252;
}

.chat-msg-outer-header-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    font-size: 15px;
    padding-bottom: 5px;
    color: #fff;
    background-color: #f5a442;
}

.chat-attachment-container {
    display: flex;
    width: 100%;
    padding-right: 15px;
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    background-color: #4a4a4a;
}

.chat-msg-outer-header-warning i::before {
    margin-right: 5px;
}

.chat-msg-input-inner-container {
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    gap: 10px;
    align-items: start;
    vertical-align: top;
    color: #fff;
    height: fit-content;
    width: 98%;
    display: flex;
    margin: auto;
    background-color: #4a4a4a;
}

.chat-msg-input-inner-container i {
    cursor: pointer;
}

.chat-msg-input-inner-container span {
    width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
    height: 75%;
    word-break: break-all;
    font-size: 15px;
    cursor: text;
    color: #fff;
    background-color: transparent;
}

.chat-msg-input-inner-container span[contenteditable]:empty::before {
    content: 'Message Ticket Chat';
    color: grey;
}

.chat-msg-content-container {
    overflow-y: auto;
    justify-content: flex-end;
    flex-direction: column;
    gap: 10px;
    display: block;
    padding: 10px 10px;
    width: 100%;
    height: 100%;
}

.chat-msg-content-container .message-container-you {
    margin-bottom: 10px;
}

.chat-msg-content-container .message-container-me {
    margin-bottom: 10px;
}

.chat-msg-container {
    display: inline-block;
    color: #fff;
    width: 100%;
}

.archived-input-inner-container {
    display: flex;
    width: 100%;
    font-size: 20px;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 960px) {
    .chat-msg-outer-container {
        width: 100%;
        height: calc(100vh - 80px);
    }

    .chat-notice-section-header {
        font-size: 12px;
    }

    .chat-outer-header {
        font-size: 20px;
    }

    .ticket-actions-button {
        font-size: 1px;
    }
    
    .chat-msg-outer-container {
        margin-top: 0px;
    }
}