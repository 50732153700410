* {
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}

.category-container {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 20px;
}

.category-body {
    display: inline-block;
    width: 100%;
    padding-left: 15px;
    padding-top: 8px;
    padding-bottom: 35px;
    max-width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding-right: 15px;
    background-color: rgb(26, 23, 23);;
}

.category-body h3 {
    margin-top: 15px;
    color: #fff;
}

.category-body textarea {
    color: #fff;
    width: 100%;
    margin-top: 10px;
    resize: vertical;
    background-color: rgba(79,79,79,0.05);
    border-radius: 3px;
    padding: 10px 10px;
    font-size: 14px;
    font-weight: 300;
}

.category-header {
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    background-color: var(--color);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.category-header i::before {
    color: var(--color);
    margin-right: 5px;
    position: inherit;
}

.category-header h3 {
    color: var(--color);
    text-align: center;
    font-size: 30px;
}

.category-instructions {
    color: #f2cf50;
}

.category-not-found {
    height: 255px;
    width: 400px;
    background-color: rgb(26, 23, 23);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 5px;
    border: 2px solid rgb(26, 23, 23);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
}

.category-not-found h3 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    margin-top: -30px;
    font-size: 32px;
    text-align: center;
}

.category-not-found p {
    display: block;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}

.category-not-found-btns-container {
    display: flex;
    text-align: center;
    width: 100%;
    margin-top: -10px;
    height: 70%;
    padding: 12px 2px;
    justify-content: center;
} 

.category-body-checkboxcontainer {
    margin-top: 15px;
    display: flex;
    vertical-align: top;
}

.category-body-checkboxcontainer input {
    width: 50px;
    margin-right: 10px;
    margin-bottom: 60px;
}

.category-body-checkboxcontainer p {
    color: #fff;
}

.category-body-btns button {
    margin-right: 10px;
    border: 1px solid rgb(26, 23, 23);
}

.category-body-btns {
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    text-align: center;
    width: 85%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
}

.container-header {
    width: 75%;
    margin: auto;
    height: 15%;
    z-index: 100;
    left: 50%;
    padding: 10px;
    margin-top: 10px;
}

.container-header p {
    font-size: 20px;
    color: #fff;
    text-align: center;
}

.container-header h3 {
    font-size: 35px;
    color: #fff;
    text-align: center;
}

@media screen and (max-width: 960px) {
    .category-header h3 {
        font-size: 20px;
    }

    .category-container {
        width: 80%
    }
}