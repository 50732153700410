.ticket-actions-container {
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    width: fit-content;
    gap: 10px;
    border-radius: 5px;
}

.ticket-actions-button {
    white-space: nowrap;
    width: auto;
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    font-size: 15px;
}

@media screen and (max-width: 960px) {
    .ticket-actions-button {
        font-size: 10px;
    }
}