.ticketlist-item-dropdown-info-claim-container {
    margin-top: 15px;
    background-color: var(--color);
    border-radius: 5px;
    align-items: center;
    padding: 2px 2px;
    padding-left: 5px;
    margin-bottom: 6px;
    display: flex;
    gap: 5px;
    flex-direction: row;
}

.ticketlist-item-dropdown-info-claim-container img {
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    width: 30px;
    border-radius: 500px;
}