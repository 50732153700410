.oauth-form {    
    height: 250px;
    width: 450px;
    background-color: rgb(26, 23, 23);
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    border-radius: 5px;
    border: 2px solid rgb(26, 23, 23);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    padding: 50px 35px;
}

.oauth-form-submit {
    width: 50%;
    position: fixed;
    text-align: center;
    top: 73%;
    left: 50%;
    transform: translate(-50%, 0)
}

.oauth-form-btns {
    display: flex;
    text-align: center;
    width: 100%;
    padding: 12px 26px;
    justify-content: center;
    left: 50%;
}

.oauth-form-submit-secondary {
    width: 40%;
    text-align: center;
    border: 1px solid rgb(26, 23, 23);
    top: 73%;
    left: 35%;
}

.oauth-form-submit-third {
    width: 40%;
    text-align: center;
    margin-left: 10px;
    top: 73%;
    left: 78%;
}

.oauth-form *{
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}

.oauth-form h3{
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    margin-top: -22px;
    font-size: 32px;
    text-align: center;
}

.oauth-form label{
    display: block;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}

.oauth-form input{
    display: block;
    height: 50px;
    width: 100%;
    text-align: center;
    color: #fff;
    background-color: rgba(79,79,79,0.05);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 300;
}

.oauth-form::placeholder{
    color: #e5e5e5;
}